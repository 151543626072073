import { NSDetailLabel, NSTitleLabel } from "components/BaseLabels"
import PrimaryButton from "components/PrimaryButton"
import RequestErrorMessages from "components/RequestErrorMessages"
import { ClientSelectBankForm } from "forms/clientPaymentForm"
import clientPaymentPathHelper from "helper/pathHelper/clientPayment"
import { getURLSearchParamsHelper } from "helper/URLSearchParamsHelper"
import useRedirectToInitPayment from "hooks/useRedirectToInitPayment"
import useSubmitButtonRef from "hooks/useSubmitButtonRef"
import PageContentSmaller from "layout/PageContentSmaller"
import PageContentWithAppLogo from "layout/PageContentWithAppLogo"
import PageFooter from "layout/PageFooter"
import PageLayout from "layout/PageLayout"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import {
    useGetShowToClientTransactionQuery,
    useUpdateClientBankMutation,
} from "store/services/clientPayments"

const ClientSelectBankPage = () => {
    useRedirectToInitPayment()
    const [submitButtonRef, handleClick] = useSubmitButtonRef()
    const uuid = getURLSearchParamsHelper('uuid')
    const {
        updateClientSelectBankPath,
        showClientWaitingForLinkPath,
        showTransactionToClientPath,
        showExplainClientSelectedBankPath,
    } = clientPaymentPathHelper(uuid)

    const {
        data,
        isFetching,
        isSuccess,
    } = useGetShowToClientTransactionQuery({ url: showTransactionToClientPath })

    const history = useHistory()

    const { t } = useTranslation(['client_payment', 'application'])

    const [updateClientBank, {
        error,
        isLoading,
    }] = useUpdateClientBankMutation()

    const handleSubmit = async (data) => {
        try {
            const result = await updateClientBank({
                url: updateClientSelectBankPath,
                body: data,
            }).unwrap()
            let redirectPath = showClientWaitingForLinkPath
            if (result.data.bridgeapi_bank.should_show_bank_explanation) {
                redirectPath = showExplainClientSelectedBankPath
            }
            history.replace(redirectPath, result)
        } catch (error) {
            console.error('ClientSelectBankPage#handleSubmit', error);
        }
    }
    let RenderContent
    if (isSuccess) {
        RenderContent = <>
            <RequestErrorMessages error={error} />

            <div className="text-margin mt-5">
                <NSTitleLabel>
                    {t('select_bank_page.greeting')}
                </NSTitleLabel>

                <NSDetailLabel>
                    {t('select_bank_page.you_have_a_pending_payment')}
                </NSDetailLabel>

                <NSDetailLabel>
                    {t('select_bank_page.please_select_bank')}
                </NSDetailLabel>
            </div>

            <ClientSelectBankForm
                data={data.data}
                submitButtonRef={submitButtonRef}
                onSubmit={handleSubmit}
            />
        </>
    }

    return (
        <PageLayout>
            <PageContentSmaller loading={isFetching}>
                <PageContentWithAppLogo
                    pageName='clientPayment'
                    body={RenderContent}
                />
            </PageContentSmaller>
            <PageFooter>

                <PrimaryButton
                    onClick={handleClick}
                    disabled={isLoading}
                >
                    {t('submit')}
                </PrimaryButton>
            </PageFooter>
        </PageLayout>
    )
}

export default ClientSelectBankPage
