import QRCode from "react-qr-code";
import { Container } from './styled'

const TransactionPaymentLinkQr = ({ status, link }) => {
    return (
        <Container status={status} className="justify-center flex">
            <QRCode value={link} size={200} />
        </Container>
    )
}

export default TransactionPaymentLinkQr
