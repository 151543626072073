export const PaymentChoice = ({ label, onClick, description, checked }) => {
    return (
      <div
        class="mb-4 border-2 border-gray-500 p-4 relative w-full"
        onClick={onClick}
      >
        <label class="flex items-center w-full">
              <input
                  type="radio"
                  name="payment"
                  value={label}
                  className="peer hidden"
                  checked={checked}
                />
                <span
                  className={`w-6 h-6 rounded-full border-[4px] border-gray-300 
                       ${checked ? "   peer-checked:bg-blue-900" : ""}`}>
                </span>
          <span class="ml-2 font-bold text-lg">{label} </span>
        </label>
        <p class="text-blue-950 pt-4 text-sm text-left">{description}</p>
        <span class="absolute top-0 right-0 mt-4 mr-4 text-blue-950">
          <i class="fas fa-award fa-2xl"></i>
        </span>
      </div>
    );
  };