import { IonIcon } from "@ionic/react";
import { settingsOutline } from "ionicons/icons";
import usePerPlatformTranslation from "hooks/usePerPlatformTranslation";

export const PaymentStatus = ({ status }) => {
  const { t } = usePerPlatformTranslation("client_payment");
  return (
    <div class="mb-6  p-4 rounded-lg text-center bg-gray-100 border border-gray-300 text-gray-600 flex items-center justify-center">
      <IonIcon icon={settingsOutline} size="large" className="mr-2" />
      <span id="status-text">
        {t("waiting_for_payment_link_page.actual_status")} : {status}
      </span>
    </div>
  );
};
