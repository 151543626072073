import { CLIENT_TRANSACTION_TAG_TYPE } from 'constants/apiConstants'
import {
    isORAATQpending,
    selectORAATQAcceptPaymentLinkState
} from 'store/slices/oneRequestAtATimeQueries'
import {
    baseSplitApi,
} from './_base'

export const clientPaymentsApi = baseSplitApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getShowToClientTransaction: builder.query({
            query: config => config,
            providesTags: () => [CLIENT_TRANSACTION_TAG_TYPE],
        }),
        updateClientAcceptPayment: builder.mutation({
            async queryFn(args, queryApi, _extraOptions, baseFetchQuery) {
                const isORAATQpendingResult = isORAATQpending(queryApi, selectORAATQAcceptPaymentLinkState)
                if (isORAATQpendingResult) return isORAATQpendingResult

                return await baseFetchQuery({
                    method: 'PATCH',
                    ...args
                })
            }
        }),
        getClientBanks: builder.query({
            query: config => config
        }),
        updateClientBank: builder.mutation({
            query: config => ({
                ...config,
                method: 'PATCH',
            }),
        }),
        updateClientExplainBank: builder.mutation({
            query: config => ({
                ...config,
                method: 'PATCH',
            }),
        }), 
        updateClientLink: builder.mutation({
            query: config => ({
                ...config,
                method: 'PATCH',
            }),
        }),
        updateClientInstantPaymentMade: builder.mutation({
            query: config => ({
                ...config,
                method: 'PATCH',
            }),
        }),
        updateClientMakeInstantPayment:  builder.mutation({
            query: config => ({
                ...config,
                method: 'PATCH',
            }),
        }),
    }),
})

export const {
    useLazyGetShowToClientTransactionQuery,
    useGetShowToClientTransactionQuery,
    useUpdateClientAcceptPaymentMutation,
    useLazyGetClientBanksQuery,
    useUpdateClientBankMutation,
    useUpdateClientExplainBankMutation,
    useUpdateClientMakeInstantPaymentMutation,
    useUpdateClientInstantPaymentMadeMutation,
    useUpdateClientLinkMutation
} = clientPaymentsApi
