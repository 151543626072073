import { IonButtons, IonIcon } from '@ionic/react'
import PrimaryButton from 'components/PrimaryButton'
import { TRANSACTIONS_BASE_PATH } from 'constants/routeConstants'
import appPathHelper from 'helper/pathHelper/app'
import transactionsPathHelper from 'helper/pathHelper/transactions'
import useIndexPagesConfig from 'hooks/useIndexPagesConfig'
import PageContent from 'layout/PageContent'
import PageHeader from 'layout/PageHeader'
import PageLayout from 'layout/PageLayout'
import { useTranslation } from 'react-i18next'
import { useLazyGetTransactionsQuery } from 'store/services/transactions'
import { isEmpty } from 'lodash'
import ContentList from 'components/ContentList'
import ContentListItem from 'components/ContentListItem'
import SearchModal from 'components/transactions/SearchModal'
import SortModal from 'components/transactions/SortModal'
import { useCurrentModal } from 'hooks/useModal'
import {
    SEARCH_TRANSACTIONS_TOGGLE_NAME,
    SORT_TRANSACTIONS_TOGGLE_NAME
} from 'constants/modalNameContants'
import FilterButton from 'components/FilterButton'
import SortButton from 'components/SortButton'
import { formatDate } from 'helper/dateHelper'
import {
    LeftContainer,
    RightContainer,
    NameContainer,
} from './styled.js'
import { formatNumberToCurrency } from 'helper/numberHelper'
import { transactionsSelectors } from 'store/slices/indexPagesConfig/transactions'
import Spinner from 'components/Spinner'
import { DetailLabel, TitleLabel } from 'components/BaseLabels'
import Cancel from '@mui/icons-material/CancelOutlined'
import CheckCircle from "@mui/icons-material/CheckCircle"
import DateRange from "@mui/icons-material/DateRange"
import { orangeStaticSpinner } from "components/icons"
import { hourglassOutline } from 'ionicons/icons'

const iconSize = '14px'

export const TransactionItem = ({ transaction, ...rest }) => {
    const { showTransactionsPath } = transactionsPathHelper(transaction.id)

    const renderIcon = () => {
        switch (transaction.status) {
            case 'pending':
                return   transaction.instant_transfer === 2 ? <IonIcon src={hourglassOutline} style={{ fontSize: iconSize , color: "var(--ion-color-tertiary)" }} /> : <IonIcon src={orangeStaticSpinner} style={{ fontSize: iconSize }} />
            case 'failed':
                return <Cancel style={{ fontSize: iconSize, color: 'var(--ion-color-danger)' }} />
            case 'success':
                return <CheckCircle style={{ fontSize: iconSize, color: 'var(--ion-color-primary-greeny-blue)' }} />
            case 'scheduled':
                return <DateRange style={{ fontSize: iconSize, color: "var(--ion-color-tertiary)" }} />
            default:
                return null;
        }
    }

    return <ContentListItem
        {...rest}
        routerLink={showTransactionsPath}
    >
        <LeftContainer slot='start'>
            <NameContainer>
                {renderIcon()}

                {transaction.full_name || '\u00A0'}
            </NameContainer>

            <DetailLabel>
                {formatDate(transaction.execution_date)}
            </DetailLabel>
        </LeftContainer>

        <RightContainer slot='end'>
            <TitleLabel className='ion-text-end'>
                {formatNumberToCurrency(transaction.amount)}
            </TitleLabel>

            <DetailLabel className='ion-text-end'>
                {transaction.label}
            </DetailLabel>
        </RightContainer>
    </ContentListItem>
}

const TransactionsIndexPage = () => {
    const { t } = useTranslation(['transaction', 'application'])
    const { rootPath } = appPathHelper()
    const { indexTransactionsPath } = transactionsPathHelper()
    const searchModal = useCurrentModal(SEARCH_TRANSACTIONS_TOGGLE_NAME)
    const sortModal = useCurrentModal(SORT_TRANSACTIONS_TOGGLE_NAME)
    const [
        pagesConfig,
        {
            setPage,
            resetPage
        },
        {
            isSuccess,
            isFetching,
            isUninitialized,
        }
    ] = useIndexPagesConfig({
        stateKey: TRANSACTIONS_BASE_PATH,
        url: indexTransactionsPath,
        api: useLazyGetTransactionsQuery
    })
    const {
        hasNext,
    } = pagesConfig

    const data = transactionsSelectors.selectAll(pagesConfig)

    let RenderLoadMoreButton
    if (isSuccess && hasNext) {
        RenderLoadMoreButton = <PrimaryButton onClick={setPage}>
            {t('load_more')}
        </PrimaryButton>
    } else if (isFetching) {
        RenderLoadMoreButton = <Spinner />
    } else if (isUninitialized && !isEmpty(data) && hasNext) {
        RenderLoadMoreButton = <PrimaryButton onClick={setPage}>
            {t('load_more')}
        </PrimaryButton>
    }

    let RenderContent
    if (data.length) {
        RenderContent = data.map((transaction) => <TransactionItem
            key={transaction.id}
            transaction={transaction}
        />)
    } else {
        RenderContent = <TitleLabel>
            {t('no_data')}
        </TitleLabel>
    }

    return (
        <PageLayout>
            <PageHeader
                withBackButton
                backTo={rootPath}
                title={t('index_page.title')}
            >
                <IonButtons slot="start">
                    <FilterButton onClick={searchModal.show} />
                </IonButtons>

                <IonButtons slot="end">
                    <SortButton onClick={sortModal.show} />
                </IonButtons>
            </PageHeader>

            <PageContent onRefresh={resetPage}>
                <SearchModal />

                <SortModal />

                <ContentList
                    containerProps={{
                        isSecondMarginInline: true
                    }}
                >
                    {RenderContent}
                </ContentList>

                <div className='ion-margin-bottom'>
                    {RenderLoadMoreButton}
                </div>
            </PageContent>
        </PageLayout>
    )
}

export default TransactionsIndexPage
