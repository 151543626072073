import clientPaymentPathHelper from "helper/pathHelper/clientPayment";
import { getURLSearchParamsHelper } from "helper/URLSearchParamsHelper";
import usePerPlatformTranslation from "hooks/usePerPlatformTranslation";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import {
  useGetShowToClientTransactionQuery,
  useUpdateClientInstantPaymentMadeMutation,
} from "store/services/clientPayments";
import { TransferIndication } from "../Components";
import { IonContent, IonFooter, IonIcon, IonModal } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import PageLayout from "layout/PageLayout";
import PageContentSmaller from "layout/PageContentSmaller";
import { BodyContainer } from "layout/PageContentWithAppLogo/styled";
import Spinner from "components/Spinner";
import { TRANSACTION_STATUS_SCHEDULED, TRANSACTION_STATUS_SUCCESS } from "constants/transactionContants";

const ClientInstantTransferPage = () => {
  const [refetchInterval, setRefetchInterval] = useState(1000);
  const { t } = usePerPlatformTranslation("client_payment");
  const uuid = getURLSearchParamsHelper("uuid");
  const { showTransactionToClientPath, showClientWaitingForLinkPath } =
    clientPaymentPathHelper(uuid);

  const [openModal, setOpenModal] = useState(false);

  const { data, isFetching } = useGetShowToClientTransactionQuery(
    {
      url: showTransactionToClientPath,
    },
    {
      pollingInterval: refetchInterval,
    }
  );

  const history = useHistory();

  const goBack = () => {
    history.push(showClientWaitingForLinkPath);
  };

  const bank_info = useMemo(() => {
    if (data?.data?.transfer_info) {
      return {
        amount: data?.data?.transfer_info?.amount,
        reference: data?.data?.transfer_info?.reference,
        iban: data?.data?.transfer_info?.beneficiary_iban,
        bic: data?.data?.transfer_info?.bic,
        beneficiary: data?.data?.transfer_info?.beneficiary_name,
      };
    }

    return {
      amount: 0,
      reference: "",
      iban: "",
      bic: "",
      beneficiary: "",
    };
  }, [data?.data?.transfer_info]);

  const { updateClientInstantPaymentMadePath } = clientPaymentPathHelper(uuid);

  const [updateTrigger] = useUpdateClientInstantPaymentMadeMutation();

  const haveMadeADeposit = async () => {
    try {
      const updateResult = await updateTrigger({
        url: updateClientInstantPaymentMadePath,
      });
      if (updateResult.data) {
        setOpenModal(true);
      } else if (updateResult?.error?.status === 422) {
        if (
          updateResult?.error?.data?.code ==
          "transaction_already_has_an_instant_transfer"
        ) {
          setOpenModal(true);
        }
        // setError(updateResult.error)
      }
    } catch (err) {
      console.error("UpdateClientAcceptPayment", err);
    }
  };

  useEffect(() => {
    if (data?.data?.transfer_info) setRefetchInterval(0);
  }, [data?.data?.transfer_info]);

  const status = useMemo(() => {
    if (!data?.data?.status) {
      return TRANSACTION_STATUS_SCHEDULED;
    }
    return data.data.status;
  }, [data?.data?.status]);

  return (
    <PageLayout>
      <PageContentSmaller loading={isFetching}>
        <BodyContainer>
          <div class="text-margin md:pt-10 pt-[0.5rem]  text-center">
            <img
              src="https://cashlee.eu/wp-content/uploads/2022/03/Cashlee_Icon_RVB_Vert.svg"
              alt="Cashlee"
              class="h-10 w-10 mx-auto mb-4"
            />
            <h1 className="text-xl font-bold uppercase">
              {t("instant_transfer.instant_transfer_with_cashlee")}
            </h1>
            <p className="mt-4">
              {t("instant_transfer.instant_transfer_with_cashlee_indication")}
            </p>
            <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("instant_transfer.important_note") }} />
             
            {isFetching && <Spinner />}
            <TransferIndication
              beneficiary={bank_info.beneficiary}
              reference={bank_info.reference}
              amount={bank_info.amount}
              iban={bank_info.iban}
              bic={bank_info.bic}
            />
            <div class="grid grid-col-12  md:flex gap-2  justify-between mt-6">
              <button
                className="flex h-10 justify-center items-center col-span-12 text-gray-600 bg-gray-100 rounded py-2 px-6 "
                onClick={goBack}
              >
                <IonIcon icon={arrowBack} size="large" className="mr-2" />
                {t("instant_transfer.back_button")}
              </button>
              <button
                className="bg-[#2E3C42] text-white col-span-12 font-bold py-2 px-2 rounded w-full h-10 uppercase"
                onClick={haveMadeADeposit}
              >
                {t("instant_transfer.have_made_transfer_button")}
              </button>
            </div>
            <p className="mt-4 text-gray-400 text-sm">
              {t("instant_transfer.for_questions")}
            </p>
          </div>

          <IonModal isOpen={openModal} onDidDismiss={() => setOpenModal(false)} className="modal-inside-payment">
            <IonContent className="ion-padding">
              <div>
                <div className="flex justify-center mb-6">
                  <img
                    src="https://cashlee.eu/wp-content/uploads/2023/01/Cashlee_Logo_RVB_Positif.png"
                    alt="Cashlee Logo"
                    className="h-14"
                  />
                </div>

                <div className="flex flex-col mt-10 mb-6 text-center items-center m-auto">
                  <h1 className="text-xl font-bold mb-4 uppercase">
                    {t("instant_transfer.follow_transaction")}
                  </h1>
                  <span className="text-sm mb-4">
                    { status === TRANSACTION_STATUS_SUCCESS ? t("instant_transfer.transaction_received") : t("instant_transfer.thank_you")}
                  </span>
                </div>
              </div>
            </IonContent>
            <IonFooter>
              <div className="flex justify-center items-center mb-5">
                <button
                  className="bg-[#32ac91] text-white font-bold py-2 px-16 rounded hover:bg-green-700"
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  {t("instant_transfer.close")}
                </button>
              </div>
            </IonFooter>
          </IonModal>
        </BodyContainer>
      </PageContentSmaller>
    </PageLayout>
  );
};

export default ClientInstantTransferPage;
