import {
    TRANSACTION_STATUS_SCHEDULED,
    TRANSACTION_STATUS_SUCCESS,
    TRANSACTION_STATUS_PENDING,
    TRANSACTION_STATUS_FAILED,
    TRANSACTION_LINK_CLICKED
} from "constants/transactionContants"
import transactionsPathHelper from "helper/pathHelper/transactions"
import PageContent from "layout/PageContent"
import PageLayout from "layout/PageLayout"
import { useParams } from "react-router-dom"
import { useGetTransactionQuery } from "store/services/transactions"
import Success from "./Success"
import Failed from "./Failed"
import Pending from "./Pending"
import { useEffect, useState } from "react"
import { TRANSACTION_WAITING_FOR_TRANSFER } from './../../../constants/transactionContants/index';

const TransactionShowPage = ({ SuccessComponent = Success }) => {
    const { id } = useParams()
    const { showTransactionsPath } = transactionsPathHelper(id)
    const [pollingInterval, setPollingInterval] = useState(0)

    const result = useGetTransactionQuery({
        url: showTransactionsPath,
        id
    }, {
        pollingInterval
    })

    const {
        isLoading,
        data
    } = result

    const status = data?.data?.status

    useEffect(() => {
        if (status === TRANSACTION_STATUS_PENDING) {
            setPollingInterval(3000)
        } else {
            setPollingInterval(0)
        }
        return () => setPollingInterval(0)
    }, [status])

    let RenderContent = null;
    if (!isLoading) {
        switch (status) {
            case TRANSACTION_STATUS_SCHEDULED:
            case TRANSACTION_WAITING_FOR_TRANSFER:
            case TRANSACTION_LINK_CLICKED:
            case TRANSACTION_STATUS_PENDING:
                RenderContent = <Pending {...result} />
                break;

            case TRANSACTION_STATUS_SUCCESS:
                RenderContent = <SuccessComponent {...result} />
                break;

            case TRANSACTION_STATUS_FAILED:
                RenderContent = <Failed {...result} />
                break;

            default:
                break;
        }
    }

    return (
        <PageLayout>
            {
                isLoading
                    ?
                    <PageContent loading={isLoading} />
                    :
                    RenderContent
            }
        </PageLayout>
    )
}

export default TransactionShowPage
