import { Preferences } from '@capacitor/preferences';

export const setLocalDataHelper = async (key, value) => {
    const stringified = JSON.stringify(value);
    await Preferences.set({
        key,
        value: stringified,
    });
};

export const getLocalDataHelper = async (key) => {
    const { value } = await Preferences.get({ key });
    return value ? JSON.parse(value) : null;
};

export const resetLocalDataHelper = async () => {
    await Preferences.clear();
};
