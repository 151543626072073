export const PaymentStep = ({ active, step, title }) => {
    return (
      <div class="flex flex-col items-center w-[110px]">
        <div
          class={`"flex items-center justify-center w-10 h-10 border-2 ${
            active
              ? "border-green-600 text-green-600 "
              : "border-black text-black "
          } rounded-full text-black font-bold"`}
        >
          {step}
        </div>
        <span class="mt-2 text-sm font-medium">{title}</span>
      </div>
    );
  };