import { IonIcon, IonToast } from "@ionic/react";
import usePerPlatformTranslation from "hooks/usePerPlatformTranslation";
import { copyOutline } from "ionicons/icons";
import { useState } from "react";

export const TransferIndication = ({
  beneficiary,
  reference,
  amount,
  iban,
  bic,
}) => {
  const { t } = usePerPlatformTranslation("client_payment");
  const [toastVisible, setToastVisible] = useState(false);

  const copyToClipboard = async (textToCopy) => {
    setToastVisible(false);
    try {
      await navigator.clipboard.writeText(textToCopy);
      setToastVisible(true); // Show the toast
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const formatedAmount = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(amount);

  return (
    <div class="mt-4 p-4 border border-gray-200 rounded-lg bg-gray-50 text-left">
      <div class="mb-2">
        <span class="text-sm">IBAN</span>
        <div class="text-gray-700 flex items-center font-semibold">
          <span>{iban}</span>
          <IonIcon
            icon={copyOutline}
            size="medium"
            className="ml-2 text-[#32ac91]"
            onClick={() => copyToClipboard(iban)}
          />
        </div>
      </div>
      <div class="mb-2">
        <span class="text-sm">{t("instant_transfer.bank_code")}</span>
        <div class="text-gray-700 flex items-center font-semibold">
          <span>{bic}</span>
          <IonIcon
            icon={copyOutline}
            size="medium"
            className="ml-2 text-[#32ac91]"
            onClick={() => copyToClipboard(bic)}
          />
        </div>
      </div>
      <div class="mb-2">
        <span class="text-sm">{t("instant_transfer.beneficiary")}</span>
        <div class="text-gray-700 flex items-center font-semibold">
          <span id="beneficiary">{beneficiary}</span>
          <IonIcon
            icon={copyOutline}
            size="medium"
            className="ml-2 text-[#32ac91]"
            onClick={() => copyToClipboard(beneficiary)}
          />
        </div>
      </div>
      <div class="mb-2">
        <span class="text-sm">{t("instant_transfer.reference")}</span>
        <div class="text-gray-700 flex items-center font-semibold">
          <span class="text-red-600">{reference}</span>
          <IonIcon
            icon={copyOutline}
            size="medium"
            className="ml-2 text-[#32ac91]"
            onClick={() => copyToClipboard(reference)}
          />
        </div>
      </div>
      <div class="mb-2">
        <span class="text-sm">{t("instant_transfer.amount")}</span>
        <div class="text-gray-700 flex items-center font-semibold">
          <span>{formatedAmount} </span>
          <IonIcon
            icon={copyOutline}
            size="medium"
            className="ml-2 text-[#32ac91]"
            onClick={() => copyToClipboard(formatedAmount)}
          />
        </div>
      </div>

      <IonToast
        isOpen={toastVisible}
        onDidDismiss={() => setToastVisible(false)}
        message={t("instant_transfer.copied")}
        duration={2000} // Toast disappears after 2 seconds
        color="success" // Optional: Set toast color
      />
    </div>
  );
};
