export const PaymentCgu = () => {
    return (
      <div class="mt-10 bg-gray-100 p-4 space-y-4">
        <div class="font-bold text-xs">
          <i class="fa-solid fa-user-shield fa-xl mr-2"></i>
          CONNEXION A VOTRE BANQUE SECURISEE PAR CASHLEE
        </div>
        <div class="text-xs">
          En continuant vous acceptez les CGU et vous autorisez Cashlee (DIGI
          INNOVA) à initier un virement via son partenaire Perspecteev qui est
          agréée sous le numéro 16918 par l’ACPR. Les CGU sont consultables{" "}
          <span class="underline">
            <a href="https://cashlee.eu/cgu-particuliers">ici.</a>
          </span>
        </div>
      </div>
    );
  };